import { cashierApi } from '@core/api';
import { API, ENV } from '@core/constants';
import rootReducer from '@core/store/reducers';
import { type RejectedActionResult } from '@core/store/types';
import {
  configureStore,
  isRejectedWithValue,
  type Middleware,
} from '@reduxjs/toolkit';
import { type GetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state) => {
    return state;
  },
});

const loggerMiddleware: Middleware<Record<string, unknown>, RootState> =
  ({ getState }) =>
  (next) =>
  async (action) => {
    if (isRejectedWithValue(action)) {
      const result = next(action) as RejectedActionResult;

      const {
        currentSettings: { jwt, transactionId },
      } = getState();

      if (!jwt) {
        return next(action);
      }

      const body = JSON.stringify({
        level: 'ERROR',
        message: result?.payload?.data?.message ?? '',
        ...(transactionId && {
          transaction_id: transactionId,
        }),
      });

      try {
        await fetch(`${ENV.VITE_CASHIER_API_URL}${API.LOGGING.LOG}`, {
          body,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Failed to log message:', error);
      }

      return result;
    }

    return next(action);
  };

const middlewareList: Middleware[] = [cashierApi.middleware];

if (['prod', 'test'].includes(ENV.VITE_ENVIRONMENT)) {
  middlewareList.push(loggerMiddleware);
}

const middlewareCallback = (getDefaultMiddleware: GetDefaultMiddleware<any>) =>
  getDefaultMiddleware().concat(middlewareList);

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: middlewareCallback,
    devTools: ENV.VITE_ENVIRONMENT === 'dev',
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers({
        autoBatch: { type: 'tick' },
      }).concat(sentryReduxEnhancer);
    },
  });

type AppDispatch = AppStore['dispatch'];
type AppStore = ReturnType<typeof setupStore>;
type RootState = ReturnType<typeof rootReducer>;

export type { AppStore, RootState, AppDispatch };

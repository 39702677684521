export function replaceURLParams(
  url: string,
  params: Record<string, number | string>
) {
  const regexPattern = new RegExp(
    Object.keys(params)
      .map((key) => `:${key}`)
      .join('|'),
    'gi'
  );
  return url.replace(regexPattern, (matched) => {
    const key = matched.replace(':', '');
    const value = params[key];
    return String(value);
  });
}

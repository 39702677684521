import { type IPaymentInstruments, type IPciSettings } from '@core/types';
import { createAction, createReducer } from '@reduxjs/toolkit';

type InitialState = IPaymentInstruments & IPciSettings;

const initialState: InitialState = {
  pciError: '',
  cardOptions: [],
  paymentInstruments: [],
  isCardDropdownOpen: false,
  isDeleteCardModalOpen: false,
  isAllDataExistInStore: false,
  isPaymentInstrumentsAvailable: false,
  cardData: {
    expYear: '',
    expMonth: '',
    cardHolder: '',
    cardNumber: null,
    pciProxyToken: null,
  },
  selectedCard: {
    id: 0,
    expYear: '',
    expMonth: '',
    blocked: false,
    cardHolder: '',
    expired: false,
    cardNumber: null,
    expiredSoon: false,
    pciProxyToken: null,
  },
  cardForDelete: {
    id: 0,
    expYear: '',
    expMonth: '',
    blocked: false,
    cardHolder: '',
    expired: false,
    cardNumber: null,
    expiredSoon: false,
    pciProxyToken: null,
  },
};

export const setPaymentInstrumentsData = createAction<
  IPaymentInstruments['paymentInstruments']
>('paymentInstruments/setPaymentInstrumentsData');

export const setIsCardDropdownOpen = createAction<
  IPciSettings['isCardDropdownOpen'] | null
>('pciSettings/setIsCardDropdownOpen');

export const setIsDeleteCardModalOpen = createAction<
  IPciSettings['isDeleteCardModalOpen'] | null
>('pciSettings/setIsDeleteCardModalOpen');

export const setCardForDelete = createAction<
  IPciSettings['cardForDelete'] | null
>('pciSettings/cardForDelete');

export const setSelectedCard = createAction<
  IPciSettings['selectedCard'] | null
>('pciSettings/setSelectedCard');

export const setCardData = createAction<IPciSettings['cardData'] | null>(
  'pciSettings/setCardData'
);

export const clearCardData = createAction('pciSettings/clearCardData');

export const setIsAllDataExistInStore = createAction<
  IPciSettings['isAllDataExistInStore']
>('pciSettings/setIsAllDataExistInStore');

export const setPciError = createAction<IPciSettings['pciError'] | null>(
  'pciSettings/setPciError'
);

export const pciSettings = createReducer(initialState, (builder) => {
  builder.addCase(setIsCardDropdownOpen, (state, action) => {
    state.isCardDropdownOpen = action.payload ?? false;
  });
  builder.addCase(setIsDeleteCardModalOpen, (state, action) => {
    state.isDeleteCardModalOpen = action.payload ?? false;
  });
  builder.addCase(setCardData, (state, action) => {
    state.cardData = action.payload ?? state.cardData;
  });
  builder.addCase(clearCardData, (state) => {
    state.cardData = {
      expYear: '',
      expMonth: '',
      cardHolder: '',
      cardNumber: null,
      pciProxyToken: null,
    };
    state.isAllDataExistInStore = false;
  });
  builder.addCase(setIsAllDataExistInStore, (state, action) => {
    state.isAllDataExistInStore = action.payload ?? false;
  });
  builder.addCase(setCardForDelete, (state, action) => {
    state.cardForDelete = action.payload ?? state.cardForDelete;
  });
  builder.addCase(setPciError, (state, action) => {
    state.pciError = action.payload ?? '';
  });
  builder.addCase(setSelectedCard, (state, action) => {
    state.selectedCard = action.payload ?? state.selectedCard;
  });
  builder.addCase(setPaymentInstrumentsData, (state, action) => {
    const data = action.payload ?? [];

    const cardsData = data.map((card) => {
      const {
        blocked,
        expYear,
        expMonth,
        cardNumber,
        cardHolder,
        pciProxyToken,
      } = card.data;
      const { id } = card;

      const cardExpireDate = new Date(
        `20${expYear}-${expMonth}-01T00:00:00.000Z`
      );
      cardExpireDate.setMonth(cardExpireDate.getMonth() + 1);

      const todayDate = new Date();
      const millisecondsDiff = cardExpireDate.getTime() - todayDate.getTime();
      const daysDiff = Math.ceil(millisecondsDiff / (1000 * 3600 * 24));

      return {
        id,
        blocked,
        expYear,
        expMonth,
        cardHolder,
        cardNumber,
        pciProxyToken,
        expired: expMonth && expYear ? millisecondsDiff <= 0 : null,
        expiredSoon:
          expMonth && expYear ? daysDiff <= 30 && daysDiff > 0 : null,
      };
    });

    const isPaymentInstrumentsAvailable = Boolean(data.length);

    state.isPaymentInstrumentsAvailable = isPaymentInstrumentsAvailable;
    state.paymentInstruments = action.payload ?? [];
    state.cardOptions = isPaymentInstrumentsAvailable ? cardsData : [];
  });
});

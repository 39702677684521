import { Box, Stack, type SxProps, useTheme } from '@mui/material';

export const NewLoader = ({
  sx = {},
  wrapper = true,
}: {
  sx?: SxProps;
  wrapper?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Stack
      sx={
        wrapper
          ? {
              height: '100lvh',
              alignItems: 'center',
              justifyContent: 'center',
              ...sx,
            }
          : sx
      }
    >
      <Stack direction="row" justifyContent="center">
        <Box className="loader-container">
          <Box className="loader-cube">
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
              className="loader-cube__inner"
            ></Box>
          </Box>
          <Box className="loader-cube">
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
              className="loader-cube__inner"
            ></Box>
          </Box>
          <Box className="loader-cube">
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
              className="loader-cube__inner"
            ></Box>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

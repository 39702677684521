import {
  type PaymentMethod,
  type ProceedKycProcedureStatus,
} from '@core/types';
import { createAction, createReducer } from '@reduxjs/toolkit';

type Page =
  | 'confirmation'
  | 'embedded'
  | 'info'
  | 'main'
  | 'maintenance'
  | 'payment'
  | 'pdf'
  | 'proceed'
  | 'status';

interface ICurrentSettings {
  additionalAmountValidationRequired: boolean;
  amount?: null | string;
  bannerMessage?: null | string;
  country?: null | string;
  currency?: null | string;
  dynamicallyUpdated?: boolean;
  hideAmount?: boolean;
  intent?: Intent | null;
  jwt?: null | string;
  kycProcessingData: {
    kyc4: {
      redirectUrl?: null | string;
      uuid: null | string;
    };
    kyc5: {
      uuid: null | string;
    };
    kycPing: {
      kyc4Status: null | ProceedKycProcedureStatus;
      kyc5Status: null | ProceedKycProcedureStatus;
    };
  };
  locale?: null | string;
  page: Page;
  paymentMethod: null | PaymentMethod;
  predefinedAmounts: null | number[];
  prohibitedAmount?: null | number;
  sandbox?: boolean | null;
  scale?: null | string;
  sessionEnvironments: {
    cashierApiDomain: string;
    cashierApiUrl: string;
    cashierEnvironment: string;
    cashierVersion: string;
  };
  sessionId?: null | string;
  shouldRefetchPaymentMethods: boolean;
  transactionId?: null | string;
}

const initialState: ICurrentSettings = {
  jwt: null,
  scale: null,
  locale: null,
  amount: null,
  page: 'main',
  intent: null,
  country: null,
  currency: null,
  sandbox: false,
  sessionId: null,
  bannerMessage: null,
  paymentMethod: null,
  transactionId: null,
  predefinedAmounts: null,
  dynamicallyUpdated: false,
  shouldRefetchPaymentMethods: false,
  additionalAmountValidationRequired: false,
  sessionEnvironments: {
    cashierApiUrl: '',
    cashierVersion: '',
    cashierApiDomain: '',
    cashierEnvironment: '',
  },
  kycProcessingData: {
    kyc5: {
      uuid: null,
    },
    kyc4: {
      uuid: null,
      redirectUrl: null,
    },
    kycPing: {
      kyc4Status: null,
      kyc5Status: null,
    },
  },
};

export const setEnvironments = createAction<
  ICurrentSettings['sessionEnvironments'] | null
>('currentSettings/setEnvironments');

export const setPaymentMethod = createAction<null | PaymentMethod>(
  'currentSettings/setPaymentMethod'
);

export const setBannerMessage = createAction<null | string>(
  'currentSettings/setBannerMessage'
);

export const setSessionData = createAction<
  Partial<{
    amount: string;
    country: string;
    currency: string;
    intent: Intent | null;
    jwt: string;
    locale: string;
    sandbox: boolean;
    scale: string;
    sessionId: string;
    transactionId: string;
  }>
>('currentSettings/setSessionData');

export const setPage = createAction<Page>('currentSettings/setPage');

export const setPredefinedAmounts = createAction<
  ICurrentSettings['predefinedAmounts'] | null
>('currentSettings/setPredefinedAmounts');

export const setProhibitedAmount = createAction<
  ICurrentSettings['prohibitedAmount'] | null
>('currentSettings/setProhibitedAmount');

export const setDynamicallyUpdated = createAction<
  ICurrentSettings['dynamicallyUpdated'] | null
>('currentSettings/setDynamicallyUpdated');

export const setAdditionalAmountValidationRequired = createAction<
  false | ICurrentSettings['additionalAmountValidationRequired']
>('currentSettings/setAdditionalAmountValidationRequired');

export const setHideAmount = createAction<
  false | ICurrentSettings['hideAmount']
>('currentSettings/setHideAmount');

export const setKyc4ProcessingData = createAction<
  ICurrentSettings['kycProcessingData']['kyc4']
>('currentSettings/kycProcessingData/kyc4');

export const setKyc5ProcessingData = createAction<
  ICurrentSettings['kycProcessingData']['kyc5']
>('currentSettings/kycProcessingData/kyc5');

export const setKyc4PingProcessingData = createAction<
  ICurrentSettings['kycProcessingData']['kycPing']['kyc4Status']
>('currentSettings/kycProcessingData/kycPing/kyc4Status');

export const setKyc5PingProcessingData = createAction<
  ICurrentSettings['kycProcessingData']['kycPing']['kyc5Status']
>('currentSettings/kycProcessingData/kycPing/kyc5Status');

export const setShouldRefetchPaymentMethods = createAction<boolean>(
  'currentSettings/setShouldRefetchPaymentMethods'
);

export const currentSettings = createReducer(initialState, (builder) => {
  builder.addCase(setPaymentMethod, (state, action) => {
    state.paymentMethod = action.payload;
  });
  builder.addCase(setPage, (state, action) => {
    state.page = action.payload;
  });
  builder.addCase(setBannerMessage, (state, action) => {
    state.bannerMessage = action.payload;
  });
  builder.addCase(setSessionData, (state, action) => {
    state.scale = action.payload.scale;
    state.amount = action.payload.amount;
    state.locale = action.payload.locale;
    state.intent = action.payload.intent;
    state.country = action.payload.country;
    state.sandbox = action.payload.sandbox;
    state.currency = action.payload.currency;
    state.sessionId = action.payload.sessionId;
    state.jwt = action.payload.jwt;
    state.transactionId = action.payload.transactionId;
  });
  builder.addCase(setEnvironments, (state, action) => {
    state.sessionEnvironments = action.payload ?? state.sessionEnvironments;
  });
  builder.addCase(setPredefinedAmounts, (state, action) => {
    state.predefinedAmounts = action.payload ?? [];
  });
  builder.addCase(setProhibitedAmount, (state, action) => {
    state.prohibitedAmount = action.payload ?? null;
  });
  builder.addCase(setDynamicallyUpdated, (state, action) => {
    state.dynamicallyUpdated = action.payload ?? false;
  });
  builder.addCase(setAdditionalAmountValidationRequired, (state, action) => {
    state.additionalAmountValidationRequired = action.payload ?? false;
  });
  builder.addCase(setHideAmount, (state, action) => {
    state.hideAmount = action.payload ?? false;
  });
  builder.addCase(setKyc4ProcessingData, (state, action) => {
    state.kycProcessingData.kyc4 = action.payload;
  });
  builder.addCase(setKyc5ProcessingData, (state, action) => {
    state.kycProcessingData.kyc5 = action.payload;
  });
  builder.addCase(setKyc4PingProcessingData, (state, action) => {
    state.kycProcessingData.kycPing.kyc4Status = action.payload;
  });
  builder.addCase(setKyc5PingProcessingData, (state, action) => {
    state.kycProcessingData.kycPing.kyc5Status = action.payload;
  });
  builder.addCase(setShouldRefetchPaymentMethods, (state, action) => {
    state.shouldRefetchPaymentMethods = action.payload;
  });
});

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getCurrentDate = () => dayjs().format('YYYY-MM-DD HH:mm:ss');
const allowedFormats = ['YYYY-M-D', 'YYYY-MM-D', 'YYYY-M-DD', 'YYYY-MM-DD'];

export function formattedDate(date: null | number, timeZone?: string): string {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
    timeStyle: 'medium',
    ...(timeZone ? { timeZone } : { timeZone: 'GMT' }),
  }).format(date);
}

export const isValidDateOfBirth = (date: number | string): boolean =>
  !dayjs(date).isBefore('1900-01-01') &&
  !dayjs(date).isAfter(dayjs()) &&
  allowedFormats.some((format) => dayjs(date).format(format) === date);

export const isUserAdult = (date: number | string): boolean =>
  dayjs().diff(dayjs(date), 'year') >= 18 &&
  dayjs().diff(dayjs(date), 'year') <= 100;

const isEmpty = <T>(value: null | T | undefined): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false;
};

const omit = <T extends Record<string, any>, K extends keyof T>(
  object: T,
  keys: K[]
): Omit<T, K> => {
  return Object.fromEntries(
    Object.entries(object).filter(([key]) => !keys.includes(key as K))
  ) as Omit<T, K>;
};

const delay = <T extends any[]>(
  fn: (...args: T) => void,
  timer: number,
  ...args: T
): NodeJS.Timeout =>
  setTimeout(() => {
    fn(...args);
  }, timer);

export { omit, delay, isEmpty };

import { createAction, createReducer } from '@reduxjs/toolkit';

interface IEmbeddedSettings {
  containerId?: null | string;
  country: null | string;
  id: null | string;
  language: null | string;
  redirectUrl?: null | string;
  token: null | string;
  type: string;
}

const initialState: IEmbeddedSettings = {
  id: null,
  type: '',
  country: '',
  token: null,
  language: '',
  redirectUrl: '',
  containerId: null,
};

export const setEmbeddedPaymentData = createAction<{
  containerId?: string;
  country: string;
  id: string;
  language: string;
  redirectUrl?: string;
  token: string;
  type: string;
}>('embeddedSettings/setEmbeddedPaymentData');

export const embeddedSettings = createReducer(initialState, (builder) => {
  builder.addCase(setEmbeddedPaymentData, (state, action) => {
    state.id = action.payload.id;
    state.type = action.payload.type;
    state.token = action.payload.token;
    state.country = action.payload.country;
    state.language = action.payload.language;
    state.containerId = action.payload.containerId;
    state.redirectUrl = action.payload.redirectUrl;
  });
});

import { INTENT_TYPE, PROVIDERS } from '@core/constants';
import { type IMethodDetails, type PaymentMethod } from '@core/types';
import { createAction, createReducer } from '@reduxjs/toolkit';

type Page =
  | 'confirmation'
  | 'embedded'
  | 'info'
  | 'loading'
  | 'main'
  | 'maintenance'
  | 'payment'
  | 'pdf'
  | 'proceed'
  | 'status';

interface IAppSessionSettings {
  isDeposit: boolean;
  page: Page;
  paymentButtonText: string;
  paymentMethodId: string;
  selectedPaymentMethod: null | PaymentMethod;
  selectedPaymentMethodDetails: IMethodDetails;
  sessionData: {
    amount?: null | number | string;
    country?: null | string;
    currency?: null | string;
    intent?: Intent | null;
    jwt?: null | string;
    language?: null | string;
    locale?: null | string;
    sandbox?: boolean | null;
    scale?: null | string;
    sessionId?: null | string;
  };
  sessionEnvironments: {
    cashierApiDomain?: string;
    cashierApiUrl?: string;
    cashierEnvironment?: string;
    cashierVersion?: string;
  };
  shouldRefetchPaymentMethods: boolean;
}

const initialState: IAppSessionSettings = {
  page: 'main',
  isDeposit: true,
  paymentMethodId: 'COMMON',
  selectedPaymentMethod: null,
  shouldRefetchPaymentMethods: false,
  paymentButtonText: 'paymentSubmission.deposit',
  sessionEnvironments: {
    cashierApiUrl: '',
    cashierVersion: '',
    cashierApiDomain: '',
    cashierEnvironment: '',
  },
  sessionData: {
    jwt: null,
    scale: null,
    amount: null,
    intent: null,
    locale: null,
    country: null,
    currency: null,
    sandbox: false,
    language: null,
    sessionId: null,
  },
  selectedPaymentMethodDetails: {
    fields: [],
    amounts: [],
    minAmount: 10,
    maxAmount: 1000,
    pciProxySid: '',
    pciProxyUrl: '',
    hideAmount: false,
    processingData: null,
    newCardAllowed: true,
    paymentInstruments: [],
    allowIntegerOnly: false,
    manualInputProhibited: false,
    additionalAmountValidationRequired: false,
  },
};

export const setSelectedPaymentMethodData = createAction<null | PaymentMethod>(
  'appSessionSetting/setSelectedPaymentMethodData'
);

export const setSelectedPaymentMethodDetailsData = createAction<IMethodDetails>(
  'appSessionSetting/setSelectedPaymentMethodDetailsData'
);

export const setAppSessionData = createAction<
  Partial<IAppSessionSettings['sessionData']>
>('appSessionSetting/setAppSessionData');

export const setEnvironmentsData = createAction<null | Record<string, string>>(
  'appSessionSetting/setEnvironmentsData'
);

export const setPage = createAction<Page>('appSessionSetting/setPage');

export const setShouldRefetchPaymentMethods = createAction<boolean>(
  'appSessionSetting/setShouldRefetchPaymentMethods'
);

export const appSessionSetting = createReducer(initialState, (builder) => {
  builder.addCase(setAppSessionData, (state, action) => {
    state.sessionData = {
      ...state.sessionData,
      ...action.payload,
      language: action.payload?.locale?.slice(0, 2).toLowerCase() ?? 'en',
    };

    state.isDeposit =
      action.payload.intent?.toUpperCase() === INTENT_TYPE.DEPOSIT;

    state.paymentButtonText =
      action.payload.intent?.toUpperCase() === INTENT_TYPE.DEPOSIT
        ? 'paymentSubmission.deposit'
        : 'paymentSubmission.withdraw';
  });
  builder.addCase(setSelectedPaymentMethodData, (state, action) => {
    state.selectedPaymentMethod = action.payload;

    const paymentMethodKey = action.payload?.pciProxy
      ? PROVIDERS.PCI
      : `${action.payload?.provider}-${action.payload?.method}-${state.sessionData.intent?.toUpperCase()}`;

    state.paymentMethodId = paymentMethodKey;
  });
  builder.addCase(setSelectedPaymentMethodDetailsData, (state, action) => {
    state.selectedPaymentMethodDetails =
      action.payload ?? state.selectedPaymentMethodDetails;
  });
  builder.addCase(setEnvironmentsData, (state, action) => {
    state.sessionEnvironments = action.payload ?? state.sessionEnvironments;
  });
  builder.addCase(setPage, (state, action) => {
    state.page = action.payload;
  });
  builder.addCase(setShouldRefetchPaymentMethods, (state, action) => {
    state.shouldRefetchPaymentMethods = action.payload;
  });
});

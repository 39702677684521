export function transformObjToCamelCase(obj: unknown): unknown {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => transformObjToCamelCase(item));
  }

  if (typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const camelKey = key
        .toLowerCase()
        .replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
      const camelCaseKey = camelKey.charAt(0).toLowerCase() + camelKey.slice(1);
      (acc as Record<string, unknown>)[camelCaseKey] =
        transformObjToCamelCase(value);
      return acc;
    }, {});
  }

  return obj;
}

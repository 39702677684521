export const ROUTES = Object.freeze({
  ALL: '*',
  ROOT: '/',
  PDF: '/pdf',
  INFO: '/info',
  DEPOSIT: '/deposit',
  WITHDRAWAL: '/withdrawal',
  DEPOSIT_PCI: '/deposit-pci',
  MAINTENANCE: '/maintenance',
  CONFIRMATION: '/confirmation',
  STATUS_TRANSACTION: '/status',
  DEPOSIT_VOLT: '/deposit-volt',
  WITHDRAWAL_PCI: '/withdrawal-pci',
  DEPOSIT_EUPAGO: '/deposit-eupago',
  DEPOSIT_BOODIL: '/deposit-boodil',
  DEPOSIT_NODAPAY: '/deposit-nodapay',
  DEPOSIT_PAYCASH: '/deposit-paycash',
  VIRTUAL_PAYMENT: '/virtual-payment',
  DEPOSIT_WEBPAYZ: '/deposit-webpayz',
  PROCEED_PAYMENT: '/proceed-payment',
  WITHDRAWAL_VOLT: '/withdrawal-volt',
  DEPOSIT_SPAYZIO: '/deposit-spayzio',
  DEPOSIT_FLEXEPIN: '/deposit-flexepin',
  DEPOSIT_EZEEBILL: '/deposit-ezeebill',
  DEPOSIT_HELP2PAY: '/deposit-help2pay',
  DEPOSIT_CHECKOUT: '/deposit-checkout',
  DEPOSIT_MIFINITY: '/deposit-mifinity',
  WITHDRAWAL_EUPAGO: '/withdrawal-eupago',
  DEPOSIT_PAYMENT_IQ: '/deposit-payment_iq',
  WITHDRAWAL_WEBPAYZ: '/withdrawal-webpayz',
  WITHDRAWAL_PAYCASH: '/withdrawal-paycash',
  DEPOSIT_PRAXIS_HPF: '/deposit-praxis-hpf',
  DEPOSIT_SAFECHARGE: '/deposit-safecharge',
  DEPOSIT_GATECHARGE: '/deposit-gatecharge',
  WITHDRAWAL_CITIZEN: '/withdrawal-citizen',
  WITHDRAWAL_EZEEBILL: '/withdrawal-ezeebill',
  WITHDRAWAL_HELP2PAY: '/withdrawal-help2pay',
  WITHDRAWAL_MIFINITY: '/withdrawal-mifinity',
  DEPOSIT_MICROPAYMENT: '/deposit-micropayment',
  DEPOSIT_PAYWITHCLICK: '/deposit-paywithclick',
  WITHDRAWAL_PAYMENT_IQ: '/withdrawal-payment_iq',
  WITHDRAWAL_SAFECHARGE: '/withdrawal-safecharge',
  WITHDRAWAL_PRAXIS_HPF: '/withdrawal-praxis-hpf',
  WITHDRAWAL_MICROPAYMENT: '/withdrawal-micropayment',
  DEPOSIT_GIGADAT_INTERAC: '/deposit-gigadat-interac',
  DEPOSIT_BOODIL_NATIONALBET: '/deposit-boodil_nationalbet',
  WITHDRAWAL_GIGADAT_INTERAC: '/withdrawal-gigadat-interac',
  DEPOSIT_PRAXIS_HPF_INTERNAL: '/deposit-praxis-hpf-internal',
  DEPOSIT_SAFECHARGE_APPLEPAY: '/deposit-safecharge-applepay',
  DEPOSIT_SAFECHARGE_GOOGLEPAY: '/deposit-safecharge-googlepay',
  WITHDRAWAL_PRAXIS_HPF_INTERNAL: '/withdrawal-praxis-hpf-internal',
  WITHDRAWAL_SAFECHARGE_APPLEPAY: '/withdrawal-safecharge-applepay',
  WITHDRAWAL_SAFECHARGE_GOOGLEPAY: '/withdrawal-safecharge-googlepay',
  INTEGRATIONS: {
    PAYMENT_IQ_HOSTED_FIELDS: '/integrations/payment-iq/hosted-fields',
  },
  EMBEDDED: {
    ROOT: '/embedded',
    DEPOSIT_VOLT_EMBEDDED: '/deposit-volt/embedded',
    DEPOSIT_MIFINITY_EMBEDDED: '/deposit-mifinity/embedded',
  },
});
